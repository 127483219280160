export const whatsappNumber = "+971522081705";
export const whatsappNumberText = "+971 522 081 705";
export const whatsappForLead="+971504506540"
export const secondaryFormTypeRent="secondary-form-rent"
export const secondaryFormTypeSale="secondary-form-sale"
export const propertyTypes = [
  {
    id: 1,
    label: "Any",
    value: "any",
    type: "Any",
  },
  {
    id: 2,
    label: "Apartments",
    value: "apartment",
    type: "Residential",
  },
  {
    id: 3,
    label: "Duplex",
    value: "duplex",
    type: "Residential",
  },
  {
    id: 4,
    label: "Labour Camp",
    value: "labour-camp",
    type: "Commercial",
  },
  {
    id: 5,
    label: "Land Residential",
    value: "land-residential",
    type: "Residential",
  },
  {
    id: 6,
    label: "Office",
    value: "office",
    type: "Commercial",
  },
  {
    id: 7,
    label: "Townhouse",
    value: "townhouse",
    type: "Residential",
  },
  {
    id: 8,
    label: "Duplex",
    value: "duplex",
    type: "Residential",
  },
  {
    id: 9,
    label: "Villa",
    value: "villa",
    type: "Residential",
  },
  {
    id: 10,
    label: "Warehouse",
    value: "warehouse",
    type: "Commercial",
  },
  {
    id: 11,
    label: "Penthouse",
    value: "penthouse",
    type: "Residential",
  },
  {
    id: 12,
    label: "Retail",
    value: "retail",
    type: "Commercial",
  },
  {
    id: 13,
    label: "Shop",
    value: "shop",
    type: "Commercial",
  },
  {
    id: 14,
    label: "Full Floor",
    value: "commercial-full-floor",
    type: "Commercial",
  },
  {
    id: 15,
    label: "Full Floor",
    value: "residential-full-floor",
    type: "Residential",
  },
  {
    id: 16,
    label: "Half Floor",
    value: "commercial-half-floor",
    type: "Commercial",
  },
  {
    id: 17,
    label: "Half Floor",
    value: "residential-half-floor",
    type: "Residential",
  },
];

export const bedrooms = [
  {
    id: 1,
    label: "1",
    value: "1",
  },
  {
    id: 2,
    label: "2",
    value: "2",
  },
  {
    id: 3,
    label: "3",
    value: "3",
  },
  {
    id: 4,
    label: "4",
    value: "4",
  },
  {
    id: 5,
    label: "5",
    value: "5",
  },
  {
    id: 6,
    label: "6",
    value: "6",
  },
  {
    id: 7,
    label: "7",
    value: "7",
  },
  {
    id: 8,
    label: "8",
    value: "8",
  },
  {
    id: 9,
    label: "9",
    value: "9",
  },
  {
    id: 10,
    label: "10",
    value: "10",
  },
];

export const price = [
  {
    id: 1,
    label: "20,000",
    value: "20000",
  },
  {
    id: 2,
    label: "30,000",
    value: "30000",
  },
  {
    id: 3,
    label: "40,000",
    value: "40000",
  },
  {
    id: 4,
    label: "50,000",
    value: "50000",
  },
  {
    id: 5,
    label: "60,000",
    value: "60000",
  },
  {
    id: 6,
    label: "70,000",
    value: "70000",
  },
  {
    id: 7,
    label: "80,000",
    value: "80000",
  },
  {
    id: 8,
    label: "90,000",
    value: "90000",
  },
  {
    id: 9,
    label: "100,000",
    value: "100000",
  },
  {
    id: 10,
    label: "120,000",
    value: "120000",
  },
  {
    id: 11,
    label: "160,000",
    value: "160000",
  },
  {
    id: 12,
    label: "180,000",
    value: "180000",
  },
  {
    id: 13,
    label: "200,000",
    value: "200000",
  },
  {
    id: 14,
    label: "250,000",
    value: "250000",
  },
  {
    id: 15,
    label: "300,000",
    value: "300000",
  },
  {
    id: 16,
    label: "400,000",
    value: "400000",
  },
  {
    id: 17,
    label: "500,000",
    value: "500000",
  },
  {
    id: 18,
    label: "600,000",
    value: "600000",
  },
  {
    id: 19,
    label: "700,000",
    value: "700000",
  },
  {
    id: 20,
    label: "800,000",
    value: "800000",
  },
  {
    id: 21,
    label: "900,000",
    value: "900000",
  },
  {
    id: 22,
    label: "1,000,000",
    value: "1000000",
  },
  {
    id: 23,
    label: "1,500,000",
    value: "1500000",
  },
  {
    id: 24,
    label: "2,000,000",
    value: "2000000",
  },
  {
    id: 25,
    label: "3,000,000",
    value: "3000000",
  },
  {
    id: 26,
    label: "4,000,000",
    value: "4000000",
  },
  {
    id: 27,
    label: "5,000,000",
    value: "5000000",
  },
  {
    id: 28,
    label: "10,000,000",
    value: "10000000",
  },
  {
    id: 29,
    label: "15,000,000",
    value: "15000000",
  },
  {
    id: 30,
    label: "20,000,000",
    value: "20000000",
  },
  {
    id: 31,
    label: "30,000,000",
    value: "30000000",
  },
  {
    id: 32,
    label: "40,000,000",
    value: "40000000",
  },
  {
    id: 33,
    label: "50,000,000",
    value: "50000000",
  },
];

export const navLinks = [
  {
    id: 1,
    label: "BUY",
    path: "/dubai/buy/residential/properties-for-sale",
  },
  {
    id: 2,
    label: "RENT",
    path: "/dubai/rent/residential/properties-for-rent",
  },
  {
    id: 3,
    label: "COMMERCIAL",
    path: "/dubai/buy/commercial/properties-for-sale",
    subLinks: [
      {
        id: 1,
        label: "Commercial for Sale",
        path: "/dubai/buy/commercial/properties-for-sale",
      },
      {
        id: 2,
        label: "Commercial for Rent",
        path: "/dubai/rent/commercial/properties-for-rent",
      },
    ],
  },
  {
    id: 4,
    label: "OFF PLAN",
    path: "/offplan",
  },
  {
    id: 5,
    label: "BLOGS",
    path: "/blogs",
  },
  {
    id: 6,
    label: "OUR TEAM",
    path: "/our-team",
  },
  {
    id: 7,
    label: "MORE",
    path: "/developers",
    subLinks: [
      {
        id: 1,
        label: "Developers",
        path: "/developers",
      },
      {
        id: 2,
        label: "Mortgage Calculator",
        path: "/mortgage-calculator",
      },
    ],
  },
  {
    id: 8,
    label: "CONTACT US",
    path: "/contact-us",
  },
];
