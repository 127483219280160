"use client";
import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import SubListLinks from "./SubListLinks";
import { subLinkPathnameActiveFinder } from "@/app/utils/utils";
import dynamic from "next/dynamic";
const DropDownArrow = dynamic(() =>
  import("../../../../assets/Icons/dropdownArrow.svg")
);
const ListLink = ({
  subLink,
  label,
  path,
  setResponsiveMenuIsActive,
  responsive,
  fullValue 
}) => {
  const pathname = usePathname();
  const [isSubLinkDropDown, setIsSubLinkDropDown] = useState(false);
  const subLinkRef = useRef(null);

  useEffect(()=>{
    if(path === fullValue?.path && subLink?.length){
      setIsSubLinkDropDown(true)
    }
  },[path])

  const responsiveMenuClickHandler = (e,sublinkClick) => {
   

    if (responsive) {

      e.stopPropagation();
      if(subLink?.length && path === fullValue?.path){
        setIsSubLinkDropDown(true)
    
        }
      if(!subLink?.length){
      setResponsiveMenuIsActive(false);
      setIsSubLinkDropDown(false)

      }

      if(sublinkClick){
      setResponsiveMenuIsActive(false);
      setIsSubLinkDropDown(false)


      }
    }
  };

  return (
    <li
      className={
        pathname === path
          ? `active link ${isSubLinkDropDown && "dropDownExist"}`
          : subLinkPathnameActiveFinder(subLink, pathname) === true
          ? `active link ${isSubLinkDropDown && "dropDownExist"}`
          : `link ${isSubLinkDropDown && "dropDownExist"}`
      }
      onClick={(e) => responsiveMenuClickHandler(e)}
      // onMouseEnter={() => setIsSubLinkDropDown(true)}
    >
      {
        responsive&&subLink?.length ? (
          <div className="linkContainer">
          <span className="label">{label}</span>
          <span className="icon">
            <DropDownArrow/>
          </span>
          </div>
         
        ):(
          <>
          <Link className="mainLink" href={path} prefetch={true}>
          {label}
        </Link>
        {
          subLink?.length&&
          <span className="icon">
          <DropDownArrow/>
        </span>
        }
         
       </>
        )
      }
     
     
        <ul
          className="subLinks gradientBorder"
          onClick={(e)=>responsiveMenuClickHandler(e,true)}
        >
          {subLink?.map((item) => (
            <SubListLinks key={item?.id} subLinkPath={item?.path} subLinkLabel={item?.label} />
          ))}
        </ul>
     
    </li>
  );
};

export default ListLink;
