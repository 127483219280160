"use client";
import dynamic from "next/dynamic";
import { Provider } from "react-redux";
import store from "./store";
import Header from "../components/Header/Header";
import { useOnInteraction } from "../components/useOnInteraction/useOnInteraction";
const Footer = dynamic(() => import("../components/Footer/Footer"));
export const ReduxProvider = ({ children }) => {
  const isUserInteracted = useOnInteraction()
  return (
    <Provider store={store}>
    
      <Header />
      {children}
      {isUserInteracted && <Footer />} 
    </Provider>
  );
};
